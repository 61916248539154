.any-navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 86.7%, 0);
}

.container-2 {
  min-width: 100%;
  margin-top: 0px;
  padding: 24px;
}

.brand-2 {
  width: 64px;
  height: 64px;
}

.nav-menu-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.any-nav-link {
  padding-right: 32px;
  padding-left: 32px;
  font-family: 'Space Mono', sans-serif;
  color: #e9e9e9;
  font-weight: 400;
  letter-spacing: 1px;
}

.any-nav-button {
  margin-right: 8px;
  margin-left: 8px;
  padding: 12px 16px;
  border-radius: 20px;
  background-color: #48d8b5;
  font-family: 'Space Mono', sans-serif;
  color: #000;
  letter-spacing: 1px;
}

.body {
  background-color: #111;
}

.any-character {
  width: auto;
}

.any-character.grandpa {
  width: auto;
}

.always-strike {
  display: inline;
  text-decoration: line-through;
}

.any-herographicwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  max-width: 70%;
  min-width: 70%;
  margin-top: 40px;
  margin-bottom: 104px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 430px;
  background-image: radial-gradient(circle closest-corner at 50% 50%, rgba(30, 15, 104, 0.59), rgba(28, 14, 97, 0.13) 40%, rgba(30, 15, 104, 0) 59%);
}

.any-product {
  margin-bottom: 56px;
}

.any-paragraph {
  font-family: 'Space Mono', sans-serif;
  color: #e9e9e9;
}

.any-paragraph.hero-info-block {
  width: 60%;
  min-width: 40%;
  margin-top: 16px;
  color: #cecece;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
}

.any-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 64px;
  margin-bottom: 160px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #e9e9e9;
  font-size: 16px;
  line-height: 24px;
}

.any-timelinecontainer {
  display: none;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.any-character-product-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 32px;
  padding-left: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.any-hero-subheader {
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: 'Space Mono', sans-serif;
  color: #48d8b5;
  text-align: center;
  letter-spacing: 1px;
}

.any-hero-info-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.any-hero-headline {
  font-family: Digitany, sans-serif;
  color: #c074ff;
  font-size: 56px;
  line-height: 72px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
  text-decoration: none;
}

.any-hero-headline-subheader {
  font-size: 36px;
}

.any-hero-headline.any-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.faq-p {
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: rgba(244, 246, 248, 0);
}

.faq-icon {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  width: 24px;
  margin-right: 16px;
}

.faq-container {
  /* max-width: 600px;
  min-width: 600px; */
  margin-top: 24px;
  margin-bottom: 60px;
}

.faq-answer {
  margin-bottom: 16px;
  font-family: 'Space Mono', sans-serif;
  color: #b8b8b8;
  line-height: 29px;
  font-weight: 400;
  letter-spacing: 1px;
}

.faq {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 80px;
  padding: 0px 50px 0px 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(44, 75, 255, 0);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  white-space: break-spaces;
}

.faq.w--open {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.faq-body-cont {
  padding: 8px 0px;
  border-top: 1px none #000;
  font-size: 16px;
  line-height: 32px;
  font-weight: 300;
}

.toggle-text {
  position: relative;
  z-index: 2;
  font-family: 'Space Mono', sans-serif;
  color: #48d8b5;
  font-weight: 400;
  letter-spacing: 1px;
}

.any-faq {
  margin-top: 120px;
  padding-left: 80px;
}

.faq-body {
  position: relative;
  display: block;
  width: 100%;
  background-color: transparent;
}

.faq-body.w--open {
  background-color: transparent;
}

.never-strike {
  /*display: none;*/
  text-decoration: line-through;
}

.always-default {
  /*display: none;*/
}

.never-default {
  cursor: auto;
}

@media screen and (min-width: 1920px) {
  .any-herographicwrap {
    width: auto;
    max-width: 70%;
    min-width: 70%;
    padding-top: 0px;
  }

  .any-paragraph.hero-info-block {
    width: auto;
    max-width: 50%;
    min-width: 50%;
    color: #b8b8b8;
    font-size: 24px;
    line-height: 40px;
  }

  .any-timelinecontainer {
    max-width: none;
    min-width: 60%;
  }

  .any-character-product-2 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .any-hero-subheader {
    font-size: 24px;
    line-height: 32px;
  }

  .any-hero-headline {
    font-size: 64px;
  }

  .any-hero-headline-subheader {
    font-size: 44px;
  }

  .faq-container {
    /* max-width: 800px;
    min-width: 800px; */
  }

  .faq-answer {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 40px;
  }

  .faq-body-cont {
    padding-top: 6px;
  }

  .toggle-text {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 40px;
  }
}

@media screen and (max-width: 991px) {
  .any-herographicwrap {
    max-width: 90%;
    min-width: 90%;
  }

  .any-product {
    max-width: none;
  }

  .any-product.rectangle {
    max-width: 90%;
  }

  .any-product.circle {
    max-width: 70px;
  }

  .any-paragraph.hero-info-block {
    width: auto;
    max-width: 22%;
    min-width: 80%;
  }

  .any-product-circle {
    max-width: 100px;
  }

  .any-character-product-2 {
    padding-right: 24px;
    padding-left: 24px;
  }

  .any-hero-headline {
    font-size: 48px;
  }

  .any-hero-headline-subheader {
    font-size: 28px;
  }

  .faq-container {
    width: 100%;
    min-width: 0px;
  }

  .any-faq {
    padding-left: 48px;
  }
}

@media screen and (max-width: 767px) {
  .any-herographicwrap {
    max-width: 90%;
    min-width: 90%;
    padding-top: 96px;
    padding-bottom: 96px;
  }

  .any-product.circle {
    max-width: 64px;
  }

  .any-paragraph.hero-info-block {
    width: auto;
    max-width: 90%;
    min-width: 90%;
    font-size: 14px;
    line-height: 24px;
  }

  .any-hero {
    margin-top: 48px;
    margin-bottom: 104px;
  }

  .any-character-product-2 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .any-hero-headline {
    font-size: 40px;
  }

  .any-hero-headline-subheader {
    font-size: 24px;
  }

  .faq-answer {
    font-family: 'Space Mono', sans-serif;
    color: #b8b8b8;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .faq {
    padding-left: 0px;
    font-size: 14px;
    line-height: 24px;
  }

  .faq-body-cont {
    padding-right: 0px;
    padding-left: 0px;
  }

  .any-faq {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 479px) {
  .container-2 {
    padding-right: 16px;
    padding-left: 16px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .brand-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .nav-menu-4 {
    display: block;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #120f22;
  }

  .any-nav-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .any-nav-button {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .any-menu.w--open {
    background-color: hsla(0, 0%, 78.4%, 0);
  }

  .icon-4 {
    color: #48d8b5;
    font-size: 34px;
  }

  .any-herographicwrap {
    max-width: 100%;
    min-width: 100%;
    padding-top: 56px;
    padding-bottom: 72px;
  }

  .any-product.rectangle {
    max-width: 70%;
  }

  .any-product.circle {
    max-width: 32px;
    margin-bottom: 40px;
  }

  .any-paragraph.hero-info-block {
    max-width: 95%;
    min-width: 95%;
    font-size: 14px;
    line-height: 24px;
  }

  .any-character-product {
    padding-right: 8px;
    padding-left: 8px;
  }

  .any-hero-headline-wrap {
    padding-right: 8px;
    padding-left: 8px;
  }

  .any-hero {
    margin-bottom: 64px;
  }

  .any-hero-subheader {
    font-size: 14px;
  }

  .any-hero-info-wrap {
    padding-right: 8px;
    padding-left: 8px;
  }

  .any-hero-headline {
    font-size: 24px;
    line-height: 48px;
  }

  .any-hero-headline-subheader {
    font-size: 20px;
    line-height: 48px;
  }

  .any-faq {
    margin-top: 64px;
  }
}
