@import "../../styles/helpers";

.wrap {
    padding: 30px 30px;
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    border: 2px solid $neutrals6;
    border-radius: 24px;
    text-align: center;
    @include d {
        margin-bottom: 32px;
        padding: 24px 16px; }
    @include dark {
        background: $neutrals2;
        border-color: $neutrals2; } }
.videoWrapper {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    position: relative;
    overflow: hidden; }

.videoPadding {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; }

.video {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
