@import "../../styles/helpers";

.footer {
    border-top: 1px solid $neutrals6;
    @include dark {
        border-color: $neutrals3; } }

.row {
    display: flex;
    padding: 80px 0 48px;
    border-bottom: 1px solid $neutrals6;
    @include t {
        display: block; }
    @include m {
        padding: 64px 0 48px; }
    @include dark {
        border-color: $neutrals3; } }

.col {
    &:first-child {
        flex-grow: 1;
        padding-right: 32px;
        @include t {
            position: relative;
            margin-bottom: 32px;
            padding: 0; } }
    &:nth-child(2) {
        display: flex;
        flex-shrink: 0;
        width: 352px;
        @include d {
            width: 276px; }
        @include t {
            width: auto;
            margin-bottom: 32px; }
        @include m {
            display: block;
            border-width: 1px 0;
            border-style: solid;
            border-color: $neutrals6;
            @include dark {
                border-color: $neutrals3; } } }
    &:nth-child(3) {
        flex-shrink: 0;
        width: 384px;
        padding-left: 32px;
        @include d {
            width: 304px; }
        @include t {
            width: 100%;
            padding: 0; } } }

.logo {
    display: inline-block;
    margin-bottom: 32px;
    img {
        width: 128px; } }

.info {
    max-width: 256px;
    @include body-1;
    @include d {
        font-size: 16px; } }

.version {
    display: flex;
    align-items: center;
    margin-top: 24px;
    @include t {
        position: absolute;
        top: 5px;
        right: 0;
        margin: 0; } }

.details {
    margin-right: 16px;
    @include caption-2;
    @include m {
        margin-right: 10px; } }

.category {
    margin-bottom: 40px;
    @include body-bold-2;
    @include t {
        margin-block-end: 24px; } }

.text {
    margin-bottom: 24px; }

.foot {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;
    @include caption-2;
    @include m {
        display: block;
        padding: 24px 0 32px; } }

.copyright {
    color: $neutrals4;
    @include m {
        text-align: center; } }

.note {
    @include m {
        display: none; }
    a {
        margin-left: 16px;
        font-weight: 600;
        color: $blue;
        transition: color .2s;
        &:hover {
            color: darken($blue, 10); } } }
