@import "../../styles/helpers";

.tableWrapper {
  overflow: auto;
  margin-bottom: 32px;
  border: 1px solid $neutrals6;
  border-radius: 6px;
  @include dark {
    border-color: $neutrals3; }
  max-height: 400px;
  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 8px 16px;
    background: #f3f2f2;
    @include dark {
      background: black; } } }

.table {
  width: 100%; }

.table td, .table th {
  padding: 10px 15px;
  border-bottom: 1px solid $neutrals6;
  @include dark {
    border-color: $neutrals3; } }

.link {
  color: $blue-light;
  transition: color .2s;
  &:hover {
    color: $blue; } }
