@import "../../../styles/helpers";

.text {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5; }

.box {
    margin-bottom: 3.5rem; }

.blurb {
    font-size: medium;
    font-weight: 500; }
