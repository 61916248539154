@import "../../styles/helpers";

.nav {
    display: flex;
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 6px;
    border-radius: 20px;
    box-shadow: inset 0 0 0 2px $neutrals6;
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals3; } }

.users {
    margin-bottom: auto; }

.link {
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.contractAddress {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 500; }
