#dapp {
  min-height: calc(100vh - 300px);
}

.container {
  min-width: 65%;
}

.loading {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 50px;
  margin-left: -50px;
  margin-top: -25px;
  text-align: center;
}

.navbar {
  background-color: #0f0823;
}

button.navbar-toggler {
  background-color: #fff;
}

.beets {
  width: 90px;
  height: 65px;
}

.nav-link {
  font-size: 16px;
  color: #fff !important;
  text-transform: uppercase;
}

a {
  text-decoration: none !important;
}

.alert.alert-danger {
  color: #fff;
  border: none;
  background-color: #E26868;
}

.alert.alert-primary {
  color: #fff;
  border: none;
  border-radius: 0;
  background-color: #694D9F;
}

.alert.alert-success {
  color: #fff;
  border: none;
  border-radius: 0;
  background-color: #20A385;
}

.header {
  position: absolute;
  top: 40%;
  left: 50%;
  max-width: 75%;
  max-height: 30vh;
  padding: 1.5rem;
  background-color: #0f0823;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.9);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.footer {
  height: 200px;
}

.footer-text {
  color: #fff;
  font-size: 18px;
}

@media (max-width: 767px) {
  .beets {
    margin-left: -25px;
  }

  .btn.btn-warning {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 5px;
  }

  .header {
    top: 35%;
    padding: 1rem;
  }
}

@media (max-width: 967px) {
  .nav-link {
    font-size: 14px;
  }
}
