@import "../../styles/helpers";

.page {
    overflow: hidden;
    background: #111; }

.toastWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 10px;
    display: flex;
    justify-content: center; }

.toastHeader {
    justify-content: space-between;
    button {
        margin: 0 !important;
        font-size: 20px;
        line-height: 1; } }
