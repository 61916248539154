@import "../../styles/helpers";

.head {
    max-width: 600px;
    margin: 0 auto 128px;
    text-align: center;
    @include x {
        margin-bottom: 112px; }
    @include m {
        margin-bottom: 64px; } }

.headALT {
    max-width: 600px;
    margin: 83px auto 50px;
    text-align: center; }

.stage {
    margin-bottom: 8px;
    @include hairline-2;
    color: $neutrals4; }

.stage2 {
    margin-bottom: 8px;
    @include hairline-1;
    color: $neutrals4;
    line-height: 1.7; }

.title {
    margin-bottom: 24px;
    @include m {
        font-size: 32px; } }

.wrapper {
    position: relative;
    margin: 0 -12px 48px; }

.row {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.player {
    flex-grow: 1;
    @include t {
        max-width: 768px;
        margin: 0 auto; } }

.videoColumn {
    width: 100%; }

.details {
    flex-shrink: 0;
    width: 352px;
    margin-left: 128px;
    padding-bottom: 80px;
    @include x {
        margin-left: 64px; }
    @include d {
        width: 304px;
        margin-left: 32px;
        padding-bottom: 64px; }
    @include t {
        width: 100%;
        margin: 48px 0 0;
        padding-bottom: 80px; }
    @include m {
        margin-top: 32px; } }

.subtitle {
    margin-bottom: 20px;
    @include d {
        font-size: 48px; } }

.line {
    display: flex;
    margin: 0 -16px 40px;
    @include d {
        margin-bottom: 24px; } }

.item {
    display: flex;
    align-items: center;
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px; }

.formItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 calc(50% - 32px);
    margin: 0 13%; }

.avatar,
.icon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 8px; }

.avatar {
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $green;
    border-radius: 50%;
    svg {
        fill: $neutrals8; } }

.description {
    flex-grow: 1; }

.category {
    @include caption-2;
    color: $neutrals4; }

.text {
    font-weight: 500; }

.wrap {
    padding: 30px 16px;
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    border: 2px solid $neutrals6;
    border-radius: 24px;
    text-align: center;
    @include d {
        margin-bottom: 32px;
        padding: 24px 16px; }
    @include dark {
        background: $neutrals2;
        border-color: $neutrals2; } }

.info {
    @include body-bold-2; }

.infoSpace {
    margin-top: 30px; }

.currency {
    @include dm-sans;
    font-size: 48px;
    line-height: (56/48);
    letter-spacing: -.02em; }

.price {
    margin-bottom: 24px;
    @include body-bold-1;
    color: $neutrals4; }

.timer {
    display: flex;
    justify-content: center;
    margin-top: 8px; }

.box {
    flex-shrink: 0;
    width: 64px;
    &:not(:last-child) {
        margin-right: 20px; } }

.number {
    @include dm-sans;
    font-size: 32px;
    line-height: (40/32);
    letter-spacing: -.01em; }

.time {
    @include body-bold-2;
    color: $neutrals4; }

.btns {
    display: flex;
    flex-direction: column;
    .button {
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 8px; } } }

.category {
    @include body-bold-2; }

.fieldset {
    margin-top: 32px;
    .field {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include m {
                margin-bottom: 20px; } } } }

.button {
    width: 100%; }

.currencyInput {
    width: 100%;
    height: 48px;
    padding: 0 14px;
    border-radius: 12px;
    border: 2px solid $neutrals6;
    @include poppins;
    @include caption-bold-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3; }
    @include placeholder {
        color: $neutrals4; }
    &:focus {
        border-color: $neutrals4; } }
