@import "../../styles/helpers";

.wrap {
    position: relative;
    text-align: center; }

.inner {
    margin-top: 15px; }

.inner p {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.7; }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals5; }

.input {
    max-width: 440px;
    height: 48px;
    padding: 0 14px;
    border-radius: 12px;
    border: 2px solid $neutrals6;
    @include poppins;
    @include caption-bold-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3; }
    @include placeholder {
        color: $neutrals4; }
    &:focus {
        border-color: $neutrals4; } }

.alert {
    position: absolute;
    bottom: -115px;
    left: 0;
    right: 0;
    min-height: 82px;
    font-weight: 600; }

.alert:after {
    content: " ";
    position: absolute;
    right: calc(50% - 12px);
    top: -12px;
    border-top: transparent;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: 12px solid #cff4fc; }

.datepickerWrapper {
    width: fit-content; }
