@import "../../styles/helpers";

.alert {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: $pink;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  word-break: break-all; }

.alertSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px 10px 10px;
  background: $pink;
  font-size: 14px;
  text-align: center;
  word-break: break-all; }

.close {
  margin-left: 10px;
  width: 40px;
  height: 40px;
  background: none;
  border-radius: 50%;
  font-size: 0;
  svg {
    fill: $neutrals2;
    transition: transform .2s; }
  @include dark {
    border-color: $neutrals3;
    background: none;
    svg {
      fill: $neutrals8; } } }
